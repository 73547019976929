<template>
  <v-container fluid>
    <v-row>
      <v-breadcrumbs :items="breadCrumbsItems" large>
        <template v-slot:divider>
          <v-icon size="11">mdi-greater-than</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">
        <ProfessionalMenu></ProfessionalMenu>
      </v-col>

      <v-col cols="12" md="9" class="mt-3">
         <v-row wrap class="productContainer">
             <v-col cols="12">
                 <v-row wrap>
                     <v-col cols="12" md="4">
                        <v-avatar size="200px">
                            <img
                                :src="userDetails.Professional.profileImageUrl"
                                alt="User Image"
                            >
                        </v-avatar>
                    </v-col>

                    <v-col cols="12" md="8">
                        <h3>Welcome back, {{userDetails.name}} </h3>
                        <v-layout>
                            <v-textarea label="Your Bio" readonly value="Hello, I'm Irozuru Chime Emmanuel, web-developer based in Umuahia, Abia State, Nigeria. I have rich experience in full-stack"></v-textarea>
                        </v-layout>
                    </v-col>
                 </v-row>

                 <v-row wrap>
                     <v-col cols="12" md="5">
                         <h6>Registered on: <span style="color:red;">{{userDetails.createdAt | moment("dddd, MMMM Do YYYY")}}</span></h6>
                     </v-col>

                     <v-col cols="12" md="3">
                         <h6>Reviews: <span style="color:red;"> 0</span></h6>
                     </v-col>

                     <v-col cols="12" md="3">
                         <h6>Status: <span v-if="userDetails.isActive"  style="color:green;"> Approved</span> <span style="color:red;" v-else> Not Approved</span> </h6>
                     </v-col>
                 </v-row>
             </v-col>
            
         </v-row>

         <v-row wrap class="mt-5 mb-5">
             <v-col cols="12" md="6" class="productContainer">
                <h5>Prescription Request</h5>
                <v-divider></v-divider>
                <v-row wrap>
                    <v-col cols="4">
                        <v-avatar>
                            <img
                                :src="userDetails.Professional.profileImageUrl"
                                alt="User Image"
                            >
                        </v-avatar>
                    </v-col>
                    <v-col cols="4">
                    </v-col>
                </v-row>
             </v-col>

             <v-col cols="12" md="5" offset-md="1" class="productContainer">
                <h5></h5>
                <v-divider></v-divider>

             </v-col>
            
         </v-row>
        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProfessionalMenu from "../others/ProfessionalMenu.vue";

export default {
  name: "ProfessionalDashboard",

  components: {
    ProfessionalMenu,
  },

  data: () => ({
    breadCrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Account",
        disabled: true,
        href: "/",
      },
    ],
    userDetails:{}
  }),
  created() {
    this.fetchUserDetail();
  },
  methods:{
    fetchUserDetail() {
      this.$http
        .get(`${process.env.VUE_APP_URL}user/` + localStorage.getItem('userId'))
        .then((response) => {
          console.log(response);
          this.userDetails = response.data.data;
        });
    }
  }
};
</script>

<style scoped>
.rowBg {
  background-color: #f2f2f2;
  border-radius: 10px;
}

.productContainer {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding:20px;
}

.iconPadding {
  padding: 50px !important;
}

.iconStyle {
  color: yellowgreen;
  font-size: 45px;
}
</style>
